/**
 * Helpers
 */
 import {
  fromBreakpoint,
  onVisibilityChange, toBreakpoint
} from './helpers'

import Choices from 'choices.js'
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'

window.Choices = Choices
window.Alpine = Alpine

Alpine.plugin(persist)
 

/**
 * Web standards
 */
import './CookieConsentv2'
import './langswitcher'
import './accordion'
import './softscroll'
import './lazyload'
import './langswitcher'
import './product-viewer/product-viewer'
import { modal } from './modal'

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector( '.wpcf7-form' );
if ( wpcf7forms ) {
  const jquery = import( 'jquery' );
  global.jQuery = require( 'jquery' );
  window.jQuery = require( 'jquery' );
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('.swiper').length > 0) {
    const swiperCss = import('swiper/css')
    const swiperCssNavigation = import('swiper/css/navigation')
    const swiperCssPagination = import('swiper/css/pagination')
  }

  if (document.querySelectorAll('div[data-modal-target]').length > 0) {
    modal.init();
  }
})

/**
 * Dynamic import scripts
 */
const elements = [
  '#algolia-search',
  '.swiper-usps',
  '.swiper-steps',
  '.single-product',
  '#countrymapwrapper'
];

[].forEach.call(elements, (element) => {
  if (element !== '') {
    if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {
      const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {
        if (element === '#algolia-search') {
          const algolia = import('./algolia')
        }

        if (element === '.swiper-usps') {
          const usps = import('./usps')
        }

        if (element === '.swiper-steps') {
          const steps = import('./steps')
        }

        if (element === '.single-product') {
          const singleProduct = import('./single-product')
        }

        if (element === '#countrymapwrapper') {
          const map = import( './map' )
        }
      })

      if (window.addEventListener) {
        addEventListener('DOMContentLoaded', lazyloadHandler, false);
        addEventListener('load', lazyloadHandler, false);
        addEventListener('scroll', lazyloadHandler, false);
        addEventListener('resize', lazyloadHandler, false);
      } else if (window.attachEvent) {
        attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
        attachEvent('onload', lazyloadHandler);
        attachEvent('onscroll', lazyloadHandler);
        attachEvent('onresize', lazyloadHandler);
      }
    }
  }
});

if (Alpine) {

  Alpine.data('configurator', (language_code) => ({
    loading: true,
    loading360: false,
    products: [],
    simple_products: [],
    countryLoadingText: '',
    countryNoResultsText: '',
    countryNoChoicesText: '',
    countryItemSelectText: '',
    colorLoadingText: '',
    colorNoResultsText: '',
    colorNoChoicesText: '',
    colorItemSelectText: '',
    colors: [],
    countryInitialized: false,
    colorFieldsInitialized: false,
    configuratorProducts: [
      {
        addons: [],
        variations: [],
        selectedProductId: null,
        selectedProduct: null,
        selectedVariationId: null,
        variationSelections: [],
        addonSelections: [],
        quantity: ''
      }
    ],
    cart: [],
    billing: {
      name: '',
      company: '',
      email: '',
      phone: '',
      country: '',
      city: ''
    },

    marketing: [],

    async init () {
      await this.setColors();
      await this.fetchProducts();
      this.setMarketingFields();

    },

    setMarketingFields () {
      const urlParams = new URLSearchParams(window.location.search);
      const marketing = []
      for (const [key, value] of urlParams) {
        marketing.push({
          key: key,
          value
        })
      }
      this.marketing = marketing
    },

    initColorFields () {
      const that = this
      const elements = document.querySelectorAll('#tp_configurator select#colorpicker');
      if (elements.length === 0) return;
      elements.forEach((element) => {
        const choices = new Choices(element, {
          allowHTML: true,
          searchEnabled: true,
          searchChoices: true,
          shouldSort: false,
          searchFields: ['label', 'value'],
          loadingText: this.colorLoadingText,
          noResultsText: this.colorNoResultsText,
          noChoicesText: this.colorNoChoicesText,
          itemSelectText: '',
          callbackOnCreateTemplates: function (template) {
            return {
              item: ({
                classNames
              }, data) => {
                const currentColor = that.colors.find((color) => color.code === data.value)
                return template(`
                  <div class="${classNames.item} ${
                  data.highlighted
                    ? classNames.highlightedState
                    : classNames.itemSelectable
                } ${
                  data.placeholder ? classNames.placeholder : ''
                }" data-item data-id="${data.id}" data-value="${data.value}" ${
                  data.active ? 'aria-selected="true"' : ''
                } ${data.disabled ? 'aria-disabled="true"' : ''}>
                    <span class="flex flex-row gap-1 items-center text-sm"><span class="w-5 h-5 rounded-full flex-shrink-0" style="background-color: ${currentColor.hex}"></span> <span>${currentColor[language_code]} <span class="opacity-75">(${currentColor.code})</span></span></span>
                  </div>
                `);
              },
              choice: ({
                classNames
              }, data) => {
                const currentColor = that.colors.find((color) => color.code === data.value)
                return template(`
                  <div class="${classNames.item} ${classNames.itemChoice} ${
                  data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                }" data-select-text="${this.config.itemSelectText}" data-choice ${
                  data.disabled
                    ? 'data-choice-disabled aria-disabled="true"'
                    : 'data-choice-selectable'
                } data-id="${data.id}" data-value="${data.value}" ${
                  data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                }>
                    <span class="flex flex-row gap-1 items-center text-xs"><span class="w-5 h-5 rounded-full flex-shrink-0" style="background-color: ${currentColor.hex}"></span> <span>${currentColor[language_code]} <span class="opacity-75">(${currentColor.code})</span></span></span>
                  </div>
                `);
              },
            };
          }
        });
      })
      this.colorFieldsInitialized = true
    },

    initCountryField () {
      const element = document.querySelector('#tp_configurator select#country');
      const choices = new Choices(element, {
        allowHTML: true,
        searchEnabled: true,
        searchChoices: true,
        shouldSort: false,
        searchFields: ['label', 'value'],
        loadingText: this.countryLoadingText,
        noResultsText: this.countryNoResultsText,
        noChoicesText: this.countryNoChoicesText,
        itemSelectText: this.countryItemSelectText,
        callbackOnCreateTemplates: function (template) {
          return {
            item: ({
              classNames
            }, data) => {
              return template(`
                <div class="${classNames.item} ${
                data.highlighted
                  ? classNames.highlightedState
                  : classNames.itemSelectable
              } ${
                data.placeholder ? classNames.placeholder : ''
              }" data-item data-id="${data.id}" data-value="${data.value}" ${
                data.active ? 'aria-selected="true"' : ''
              } ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span class="flex flex-row gap-1 items-center"><img src="/wp-content/themes/toypek/lib/flags/${data.value}.svg" width="16px" height="12px" style="border-radius: 2px; overflow: hidden;margin-bottom: 1px;"> ${data.label}</span>
                </div>
              `);
            },
            choice: ({
              classNames
            }, data) => {
              return template(`
                <div class="${classNames.item} ${classNames.itemChoice} ${
                data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-select-text="${this.config.itemSelectText}" data-choice ${
                data.disabled
                  ? 'data-choice-disabled aria-disabled="true"'
                  : 'data-choice-selectable'
              } data-id="${data.id}" data-value="${data.value}" ${
                data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
              }>
                  <span class="flex flex-row gap-1 items-center"><img src="/wp-content/themes/toypek/lib/flags/${data.value}.svg" width="16px" height="12px" style="border-radius: 2px; overflow: hidden;margin-bottom: 1px;"> ${data.label}</span>
                </div>
              `);
            },
          };
        }
      });

      choices.passedElement.element.addEventListener('choise', (event) => {
        this.billing.country = event.detail.value.value
      })

      if (language_code) {
        this.billing.country = language_code
        choices.setChoiceByValue(language_code)
      }
      this.countryInitialized = true;
    },

    addNewConfiguratorProduct () {
      this.configuratorProducts.push({
        variations: [],
        addons: [],
        selectedProductId: null,
        selectedProduct: null,
        variationSelections: [],
        addonSelections: [],
        quantity: ''
      })
    },
    
    async onProductSelect(configuratorIndex, productId) {
      // Retrieve variations and update the configurator
      if (this.configuratorProducts[configuratorIndex].selectedProductId === productId) return;
      this.configuratorProducts[configuratorIndex].addonSelections = []
      this.configuratorProducts[configuratorIndex].variationSelections = []
      this.configuratorProducts[configuratorIndex].selectedVariationId = null
      this.configuratorProducts[configuratorIndex].selectedProductId = null
      this.configuratorProducts[configuratorIndex].selectedProduct = null
      this.configuratorProducts[configuratorIndex].variations = []
      this.configuratorProducts[configuratorIndex].addons = []
      this.configuratorProducts[configuratorIndex].quantity = ''
      this.configuratorProducts[configuratorIndex].selectedProductId = productId;
      this.configuratorProducts[configuratorIndex].selectedProduct = this.products.find(product => product.id === productId);

      await this.$nextTick();
      const pv = new ProductViewer({
        element: document.getElementById('pv-' + configuratorIndex),
        imagesViaLink: true,
        images: this.configuratorProducts[configuratorIndex].selectedProduct.product_images.map((image) => image.url),
        numberOfImages: 12,
        invertMovement: true,
        autoRotate: true
      });
      const pvMobile = new ProductViewer({
        element: document.getElementById('pv-mobile-' + configuratorIndex),
        imagesViaLink: true,
        images: this.configuratorProducts[configuratorIndex].selectedProduct.product_images.map((image) => image.url),
        numberOfImages: 12,
        invertMovement: true,
        autoRotate: true
      });

      /**
       * Add variations to the configurator
       */
      this.configuratorProducts[configuratorIndex].variations = await this.getVariationsDataByBySelectedProduct(configuratorIndex);
      this.configuratorProducts[configuratorIndex].variationSelections = this.configuratorProducts[configuratorIndex].variations.map(variation => {
        return {
          ...variation,
          value: null,
          custom_value: null
        }
      })

      /**
       * Add addons to the configurator
       */
      this.configuratorProducts[configuratorIndex].addonSelections = []
      this.configuratorProducts[configuratorIndex].addons = this.configuratorProducts[configuratorIndex].selectedProduct.product_addons

      /**
       * Scroll to option 2
       */
      const configurator = document.querySelector('#modal_configurator .modal-scroll-handler')
      console.log(configurator)
      const steps = document.querySelectorAll(`#product-${configuratorIndex} .configurator--step`)
      const step = steps[1]
      configurator.scrollTo({
        top: step.offsetTop,
        behavior: 'smooth'
      })
    },

    async onVariationSelect (configuratorIndex, variationIndex, variationName, variationOption, variationGallery, optionId) {
      this.configuratorProducts[configuratorIndex].variationSelections[variationIndex].value = variationOption;
      this.configuratorProducts[configuratorIndex].variationSelections[variationIndex].image_gallery = variationGallery;
      this.configuratorProducts[configuratorIndex].variationSelections[variationIndex].id = optionId;
      this.configuratorProducts[configuratorIndex].selectedVariationId = optionId;

      const optionInVariation = this.configuratorProducts[configuratorIndex].variations[variationIndex].options.find(option => {
        return option.name === variationOption
      })
      if (optionInVariation) {
        if (optionInVariation.custom_input === false) {
          this.configuratorProducts[configuratorIndex].variationSelections[variationIndex].custom_value = null;
        }
      }

      this.configuratorProducts[configuratorIndex].variations = this.getVariationsDataByBySelectedProduct(configuratorIndex)
      
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }

      /**
       * Add addons to the configurator
        */
      const possibleAddons = this.configuratorProducts[configuratorIndex].selectedProduct.product_addons_combinations.filter((possibleAddon) => {
        return parseInt(possibleAddon.variation_id) === optionId
      })
      let addonsIds = []
      for (const possibleAddon of possibleAddons) {
        addonsIds = addonsIds.concat(possibleAddon.product_ids)
      }
      addonsIds = addonsIds.filter(onlyUnique)
      let addons = addonsIds.map((addonId) => {
        return this.simple_products.find((simple_product) => simple_product.id === addonId)
      })

      addons = addons.map((addon) => {
        if (addon && addon.hasOwnProperty('id') && addon.product_tooltip !== null) {
          return {
            id: addon.id,
            name: addon.title,
            image: addon.featured_image,
            tooltip: addon.product_tooltip
          }
        }
      })

      this.configuratorProducts[configuratorIndex].addons = addons

      /**
       * Only set gallery images if no addons are selected
       */
      if (this.configuratorProducts[configuratorIndex].addonSelections.length === 0) {
        const variationCategory = this.configuratorProducts[configuratorIndex].variations.find((innerVariation, index) => {
          if (index !== variationIndex) return false
          return innerVariation.name === variationName
        })

        const variation = variationCategory.options.find((option, index) => {
          return option.name === variationOption
        })

        if (variation && variation.gallery.length > 0) {
          this.loading360 = true;
          await this.$nextTick();
          const pv = new ProductViewer({
            element: document.getElementById('pv-' + configuratorIndex),
            imagesViaLink: true,
            images: variation.gallery.map((image) => image.url),
            numberOfImages: 12,
            invertMovement: true,
            autoRotate: true
          });
          const pvMobile = new ProductViewer({
            element: document.getElementById('pv-mobile-' + configuratorIndex),
            imagesViaLink: true,
            images: variation.gallery.map((image) => image.url),
            numberOfImages: 12,
            invertMovement: true,
            autoRotate: true
          });
          setTimeout(() => {
            this.loading360 = false;
          }, 300)
        }
      } else {
        /**
         * Else set images with the new variation product and their desired product addons
         */
        this.setCombinationGalleryImages(configuratorIndex)
      }
    },

    async onAddonSelect (configuratorIndex, addonIndex, id) {
      const index = this.configuratorProducts[configuratorIndex].addonSelections.findIndex(addon => addon === id)
      // Deselect addon
      if (index !== -1) {
        this.configuratorProducts[configuratorIndex].addonSelections.splice(index, 1)
        if (this.configuratorProducts[configuratorIndex].addonSelections.length === 0) {
          const variation = this.configuratorProducts[configuratorIndex].selectedProduct.product_variations.find(variation => {
            return variation.attributes.find(attribute => {
              return attribute.option === this.configuratorProducts[configuratorIndex].variationSelections[this.configuratorProducts[configuratorIndex].variationSelections.length - 1].value
            })
          })

          if (variation && variation.product_configurator_gallery_images.length > 0) {

            console.log({
              chosenVariation: variation
            })

            this.loading360 = true;
            await this.$nextTick();
            const pv = new ProductViewer({
              element: document.getElementById('pv-' + configuratorIndex),
              imagesViaLink: true,
              images: variation.product_configurator_gallery_images.map((image) => image.url),
              numberOfImages: 12,
              invertMovement: true,
              autoRotate: true
            });
            const pvMobile = new ProductViewer({
              element: document.getElementById('pv-mobile-' + configuratorIndex),
              imagesViaLink: true,
              images: variation.product_configurator_gallery_images.map((image) => image.url),
              numberOfImages: 12,
              invertMovement: true,
              autoRotate: true
            });
            setTimeout(() => {
              this.loading360 = false;
            }, 300)
          }
        } else {
          this.setCombinationGalleryImages(configuratorIndex)
        }
      } 
      // select addon
      else {
        this.configuratorProducts[configuratorIndex].addonSelections.push(id)
        this.setCombinationGalleryImages(configuratorIndex)
      }
    },
    
    async setCombinationGalleryImages (configuratorIndex) {
      const combination = this.configuratorProducts[configuratorIndex].selectedProduct.product_addons_combinations.find(combination => {
        return combination.variation_id === this.configuratorProducts[configuratorIndex].selectedVariationId &&
          combination.product_ids.length === this.configuratorProducts[configuratorIndex].addonSelections.length &&
          combination.product_ids.every(product_id => this.configuratorProducts[configuratorIndex].addonSelections.includes(product_id));
      });
      

      // set gallery images
      if (combination) {
        if (combination.image_gallery !== false) {
          this.loading360 = true;
          await this.$nextTick();
          const pv = new ProductViewer({
            element: document.getElementById('pv-' + configuratorIndex),
            imagesViaLink: true,
            images: combination.image_gallery.map((image) => image.url),
            numberOfImages: 12,
            invertMovement: false,
            autoRotate: true
          });
          const pvMobile = new ProductViewer({
            element: document.getElementById('pv-mobile-' + configuratorIndex),
            imagesViaLink: true,
            images: combination.image_gallery.map((image) => image.url),
            numberOfImages: 12,
            invertMovement: false,
            autoRotate: true
          });
          setTimeout(() => {
            this.loading360 = false;
          }, 300)
        }
      }
    },

    async resetVariation (configuratorIndex, variationIndex) {
      this.$data.configuratorProducts[configuratorIndex].variationSelections[variationIndex].value = null
      this.$data.configuratorProducts[configuratorIndex].variationSelections[variationIndex].gallery = []
      this.$data.configuratorProducts[configuratorIndex].variationSelections[variationIndex].id = null
      this.configuratorProducts[configuratorIndex].variations = this.getVariationsDataByBySelectedProduct(configuratorIndex)
      
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }

      this.configuratorProducts[configuratorIndex].addons = []

      /**
       * Only set gallery images if no addons are selected
       */
      if (this.configuratorProducts[configuratorIndex].addonSelections.length === 0) {
        const variationCategory = this.configuratorProducts[configuratorIndex].variations.find((innerVariation, index) => {
          if (index !== variationIndex) return false
          return innerVariation.name === variationName
        })
6
        const variation = variationCategory.options.find((option, index) => {
          return option.name === variationOption
        })

        if (variation && variation.gallery.length > 0) {
          this.loading360 = true;
          await this.$nextTick();
          const pv = new ProductViewer({
            element: document.getElementById('pv-' + configuratorIndex),
            imagesViaLink: true,
            images: variation.gallery.map((image) => image.url),
            numberOfImages: 12,
            invertMovement: true,
            autoRotate: true
          });
          const pvMobile = new ProductViewer({
            element: document.getElementById('pv-mobile-' + configuratorIndex),
            imagesViaLink: true,
            images: variation.gallery.map((image) => image.url),
            numberOfImages: 12,
            invertMovement: true,
            autoRotate: true
          });
          setTimeout(() => {
            this.loading360 = false;
          }, 300)
        }
      } else {
        /**
         * Else set images with the new variation product and their desired product addons
         */
        this.setCombinationGalleryImages(configuratorIndex)
      }
    },

    getTranslatedAttributeTitle (attributeOption) {
      if (attributeOption === 'orange-en') return 'Orange'
      if (attributeOption === 'orange-fr') return 'Orange'
      if (attributeOption === 'custom-color') return 'Custom color'
      if (attributeOption === 'eigen-kleur-de') return 'Eigene Farbe'
      if (attributeOption === 'eigen-kleur') return 'Eigen kleur'
      if (attributeOption === 'eigen-kleur-es') return 'Propio color'
      if (attributeOption === 'eigen-kleur-fr') return 'Couleur personnalisée'
      if (attributeOption === 'gris-fr') return 'Gris'
      if (attributeOption === 'gris-fr') return 'Gris'
      if (attributeOption === 'gris-fr') return 'Gris'
      if (attributeOption === 'blue-fr') return 'Bleu'
      if (attributeOption === 'blue-fr') return 'Bleu'
      if (attributeOption === 'one-mobile-toilet-one-sanitary-unit') return 'One mobile toilet & one sanitary unit'
      if (attributeOption === 'une-toilette-mobile-et-un-bloc-sanitaire') return 'Une toilette mobile et un bloc sanitaire'
      if (attributeOption === 'eine-mobile-toilette-und-eine-sanitareinheit') return 'Eine mobile Toilette und eine Sanitäreinheit'
      if (attributeOption === 'un-inodoro-movil-y-una-unidad-sanitaria') return 'Un inodoro móvil y una unidad sanitaria'
      if (attributeOption === 'one-mobile-toilet-one-shower-cubicle') return 'One mobile toilet & one shower cubicle'
      if (attributeOption === 'une-toilette-mobile-et-une-cabine-de-douche') return 'Une toilette mobile et une cabine de douche'
      if (attributeOption === 'eine-mobile-toilette-und-eine-duschkabine') return 'Eine mobile Toilette und eine Duschkabine'
      if (attributeOption === 'un-inodoro-movil-y-una-cabina-de-ducha') return 'Un inodoro móvil y una cabina de ducha'
      if (attributeOption === 'one-shower-cubicle-one-sanitary-unit') return 'One shower cubicle & one sanitary unit'
      if (attributeOption === 'une-cabine-de-douche-et-un-bloc-sanitaire') return 'Une cabine de douche et un bloc sanitaire'
      if (attributeOption === 'eine-duschkabine-und-eine-sanitareinheit') return 'Eine Duschkabine und eine Sanitäreinheit'
      if (attributeOption === 'una-cabina-de-ducha-y-una-unidad-sanitaria') return 'Una cabina de ducha y una unidad sanitaria'
      if (attributeOption === 'two-mobile-toilets') return 'Two mobile toilets'
      if (attributeOption === 'deux-toilettes-mobiles') return 'Deux toilettes mobiles'
      if (attributeOption === 'zwei-mobile-toiletten') return 'Zwei mobile Toiletten'
      if (attributeOption === 'dos-banos-moviles') return 'Dos baños móviles'
      if (attributeOption === 'two-sanitary-units') return 'Two sanitary units'
      if (attributeOption === 'deux-blocs-sanitaires') return 'Deux blocs sanitaires'
      if (attributeOption === 'zwei-sanitareinheiten') return 'Zwei Sanitäreinheiten'
      if (attributeOption === 'dos-unidades-sanitarias') return 'Dos unidades sanitarias'
      if (attributeOption === 'two-shower-cabins') return 'Two shower cabins'
      if (attributeOption === 'deux-cabines-de-douche') return 'Deux cabines de douche'
      if (attributeOption === 'zwei-duschkabinen') return 'Zwei Duschkabinen'
      if (attributeOption === 'dos-cabinas-de-ducha') return 'Dos cabinas de ducha'
      if (attributeOption === 'with-wc') return 'With WC'
      if (attributeOption === 'avec-wc') return 'Avec WC'
      if (attributeOption === 'mit-wc') return 'Mit WC'
      if (attributeOption === 'con-wc') return 'con WC'
      if (attributeOption === 'with-options') return 'With options'
      if (attributeOption === 'avec-options') return 'Avec options'
      if (attributeOption === 'mit-optionen') return 'Mit Optionen'
      if (attributeOption === 'con-opciones') return 'Con opciones'
      if (attributeOption === 'without-options') return 'Without options'
      if (attributeOption === 'sans-choix') return 'Sans choix'
      if (attributeOption === 'ohne-optionen') return 'Ohne Optionen'
      if (attributeOption === 'sin-opciones') return 'Sin opciones'
      if (attributeOption === 'without-unit') return 'Without unit'
      if (attributeOption === 'sans-unite') return 'Sans unité'
      if (attributeOption === 'ohne-einheiten') return 'Ohne Einheiten'
      if (attributeOption === 'sin-unidade') return 'Sin unidade'
      if (attributeOption === 'without-units') return 'Without units'
      if (attributeOption === 'sans-unites') return 'Sans unités'
      if (attributeOption === 'ohne-einheiten-de') return 'Ohne Einheiten'
      if (attributeOption === 'sin-unidades') return 'Sin unidades'
      if (attributeOption === 'with-sanitary-unit') return 'With sanitary unit'
      if (attributeOption === 'avec-bloc-sanitaire') return 'Avec bloc sanitaire'
      if (attributeOption === 'mit-sanitareinheit') return 'Mit Sanitäreinheit'
      if (attributeOption === 'con-unidad-sanitaria') return 'con unidad sanitaria'
      if (attributeOption === 'with-shower-cubicle') return 'With shower cubicle'
      if (attributeOption === 'avec-cabine-de-douche') return 'Avec cabine de douche'
      if (attributeOption === 'mit-duschkabine') return 'Mit Duschkabine'
      if (attributeOption === 'con-cabina-de-ducha') return 'con cabina de ducha'
      if (attributeOption === 'een-mobiel-toilet-een-sanitaire-unit') return 'Eén mobiel toilet & één sanitaire unit'
      if (attributeOption === 'een-mobiel-toilet-een-douchecabine') return 'Eén mobiel toilet & één douchecabine'
      if (attributeOption === 'een-douchecabine-een-sanitaire-unit') return 'Eén douchecabine & één sanitaire unit'
      if (attributeOption === 'twee-mobiele-toiletten') return 'Twee mobiele toiletten'
      if (attributeOption === 'twee-sanitaire-units') return 'Twee sanitaire units'
      if (attributeOption === 'twee-douchecabines') return 'Twee douchecabines'
      if (attributeOption === 'met-toilet') return 'Met toilet'
      if (attributeOption === 'met-opties') return 'Met opties'
      if (attributeOption === 'zonder-opties') return 'Zonder opties'
      if (attributeOption === 'zonder-unit') return 'Zonder unit'
      if (attributeOption === 'met-sanitaire-unit') return 'Met sanitaire unit'
      if (attributeOption === 'met-douchecabine') return 'Met douchecabine'
      return attributeOption
    },

    getVariationsDataByBySelectedProduct (configuratorIndex) {
      if (this.configuratorProducts[configuratorIndex].selectedProduct === null) return [];
      
      const attributes = this.configuratorProducts[configuratorIndex].selectedProduct.attributes;
      const product_variations = this.configuratorProducts[configuratorIndex].selectedProduct.product_variations;

      const variationsWithOptions = []

      attributes.forEach((attribute, index) => {
        if (typeof attribute.options === 'object' &&
          !Array.isArray(attribute.options) &&
          attribute.options !== null
        ) {
          attributes[index].options = Object.values(attribute.options)
        }
      })

      for (const [attributeIndex, attribute] of attributes.entries()) {
        const attributeName = attribute.name;
        const attributePreIndex = this.configuratorProducts[configuratorIndex].variations.length > 0 ? this.configuratorProducts[configuratorIndex].variations.findIndex(variation => variation.name === attributeName) : null
        const variationIndex = {
          name: attributeName,
          options: []
        }

        function isIterable(input) {  
          if (input === null || input === undefined) {
            return false
          }
        
          return typeof input[Symbol.iterator] === 'function'
        }

        if (isIterable(attribute.options)) {
          attribute.options.forEach((option) => {

            const optionName = option;
            const variation = product_variations.find((variation, index) => {
              return variation.attributes.find(attribute => {

                const foundIndex = this.configuratorProducts[configuratorIndex].variationSelections.findIndex((variationSelection, index) => {
                  return variationSelection.value !== null && index !== attributeIndex
                })

                if (foundIndex === -1) {
                  return attribute.name === attributeName
                    && attribute.option === optionName         
                } else {
                  return variation.attributes[foundIndex].option === this.configuratorProducts[configuratorIndex].variationSelections[foundIndex].value
                    && attribute.name === attributeName
                    && attribute.option === optionName
                }
              })
            })

            if (variation) {
              variationIndex.options.push({
                name: optionName,
                title: this.getTranslatedAttributeTitle(optionName),
                id: variation.id,
                image: variation.product_configurator_image,
                tooltip: variation.product_configurator_tooltip,
                gallery: variation.product_configurator_gallery_images,
                custom_input: variation.product_configurator_custom_input,
                attributes: variation.attributes,
                inactive: false
              })
            } else {
              variationIndex.options.push({
                name: optionName,
                title: '',
                id: null,
                image: {},
                tooltip: '',
                gallery: [],
                custom_input: false,
                attributes: [],
                inactive: true
              })
            }

          })
        }

        if (attributePreIndex !== null) { 
          variationsWithOptions[attributePreIndex] = variationIndex
        } else {
          variationsWithOptions.push(variationIndex)
        }
      }


      variationsWithOptions.forEach((variation, index) => {
        variation.inactive = variation.options.every(option => option.id === null)
      })
      
      return variationsWithOptions
    },

    canShowFinishStep() {
      let canShow = false
      canShow = this.configuratorProducts.every((configuratorProduct) => {
        return configuratorProduct.selectedProductId !== null
          && configuratorProduct.variationSelections.every((variationSelection, variationSelectionIndex) => {
            return ((variationSelection.value !== null && configuratorProduct.variations[variationSelectionIndex].inactive === false)
            || (variationSelection.value === null && configuratorProduct.variations[variationSelectionIndex].inactive === true))
          })
      })

      if (canShow && !this.countryInitialized) {
        this.initCountryField()
      }

      if (!this.colorFieldsInitialized) {
        this.initColorFields()
      }

      return canShow
    },

    getAddonSelectionsString(configuratorIndex) {
      return this.configuratorProducts[configuratorIndex].addonSelections.map((addonId) => {
        const addon = this.configuratorProducts[configuratorIndex].addons.find((addon) => addon.id === addonId)
        return addon.name
      }).join(', ')
    },
    onFinish() { // configurator
      const errorWrapper = document.querySelector('#configurator-error')
      const errorMessageWrapper = document.getElementById('configurator-error-message')
      const submitButton = document.getElementById('configurator-submit-button')
      jQuery.ajax({
        type: 'POST',
        url: toypek_configurator.ajaxurl,
        dataType: 'json',
        data: {
          'nonce': toypek_configurator.security,
          'action': 'toypek_configurator_submit',
          'language': language_code,
          'configurator_products': JSON.stringify(this.configuratorProducts),
          'billing': JSON.stringify(this.billing),
          'marketing': JSON.stringify(this.marketing)
        },
        beforeSend: function () {
          submitButton.disabled = true
          errorMessageWrapper.innerHTML = ''
          errorWrapper.classList.add('hidden')
          errorWrapper.classList.remove('flex')
        },
        success: function (response) {
          const { data } = response
          if (data.valid === true) {
            if (data.redirect_url && data.redirect_url !== '') {
              window.location.href = data.redirect_url
            } else {
              submitButton.disabled = false
            }
          } else {
            if (data.valid === false) {
              if (data.missing_fields && data.missing_fields.length > 0) {
                const form = document.getElementById('configurator-input-fields');
                
                data.missing_fields.forEach((f) => {
                  const inputs = form.querySelectorAll('input[name="' + f + '"]');
                  
                  inputs.forEach(function(input) {
                    input.classList.add('validation-error');
                    input.addEventListener('change', handleInputChange);
                    input.addEventListener('input', handleInputChange);
                  });
                })
                
                function handleInputChange(e) {
                  e.target.classList.remove('validation-error');
                }
              }
              if (data.message && data.message !== '') {
                errorMessageWrapper.innerHTML = data.message
                errorWrapper.classList.remove('hidden')
                errorWrapper.classList.add('flex')
                submitButton.disabled = false
              }
            }
          }
        },
        error: function (xhr, status, error) {
          console.log(error)
          console.log(xhr)
          console.log(status)
          submitButton.disabled = false
        }
      })
    },

    async fetchProducts() {
      const products = [];

      try {
        // Fetch the index.json files from the specified directory
        const response = await fetch(`/wp-content/themes/toypek/lib/products/${language_code}/index.json`);
        const productIds = await response.json();

        // Loop through the directories and fetch the index.json files
        for (const productId of productIds) {
          const indexResponse = await fetch(`/wp-content/themes/toypek/lib/products/${language_code}/${productId}/index.json`);
          const indexData = await indexResponse.json();
          products.push(indexData);
          this.products = products.filter(product => product.show_in_configurator === true);
          this.simple_products = products.filter(product => product.show_in_configurator === false);
        }       
        return products;
      } catch (error) {
        console.log(error);
        throw new Error('Failed to fetch products.');
      } finally {
        this.loading = false;
      }
    },

    async setColors () {
      this.colors = require('./colors.json')
    }
  }))

  Alpine.data('quote', (language_code) => ({
    loading: true,
    loading360: false,
    products: [],
    countryLoadingText: '',
    countryNoResultsText: '',
    countryNoChoicesText: '',
    countryItemSelectText: '',
    countryInitialized: false,
    productFieldInitialized: false,
    selectedProductId: null,
    selectedProduct: null,
    quantity: '',
    billing: {
      name: '',
      company: '',
      email: '',
      phone: '',
      country: '',
      city: ''
    },

    async init (callback) {
      await this.fetchProducts()
        .then((products) => {
          this.selectedProduct = this.products.find(product => product.id === parseInt(this.selectedProductId));
        })
        .catch((error) => {
          console.log(error)
          throw new Error(error)
        })
        .finally(() => {
          if (this.productFieldInitialized === false) {
            this.initProductField();
          }
          if (this.countryInitialized === false) {
            this.initCountryField();
          }
        })
    },

    initCountryField () {
      const element = document.querySelector('#tp_quote select#country');
      const choices = new Choices(element, {
        allowHTML: true,
        searchEnabled: true,
        searchChoices: true,
        shouldSort: false,
        searchFields: ['label', 'value'],
        loadingText: this.countryLoadingText,
        noResultsText: this.countryNoResultsText,
        noChoicesText: this.countryNoChoicesText,
        itemSelectText: this.countryItemSelectText,
        callbackOnCreateTemplates: function (template) {
          return {
            item: ({
              classNames
            }, data) => {
              return template(`
                <div class="${classNames.item} ${
                data.highlighted
                  ? classNames.highlightedState
                  : classNames.itemSelectable
              } ${
                data.placeholder ? classNames.placeholder : ''
              }" data-item data-id="${data.id}" data-value="${data.value}" ${
                data.active ? 'aria-selected="true"' : ''
              } ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span class="flex flex-row gap-1 items-center"><img src="/wp-content/themes/toypek/lib/flags/${data.value}.svg" width="16px" height="12px" style="border-radius: 2px; overflow: hidden;margin-bottom: 1px;"> ${data.label}</span>
                </div>
              `);
            },
            choice: ({
              classNames
            }, data) => {
              return template(`
                <div class="${classNames.item} ${classNames.itemChoice} ${
                data.disabled ? classNames.itemDisabled : classNames.itemSelectable
              }" data-select-text="${this.config.itemSelectText}" data-choice ${
                data.disabled
                  ? 'data-choice-disabled aria-disabled="true"'
                  : 'data-choice-selectable'
              } data-id="${data.id}" data-value="${data.value}" ${
                data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
              }>
                  <span class="flex flex-row gap-1 items-center"><img src="/wp-content/themes/toypek/lib/flags/${data.value}.svg" width="16px" height="12px" style="border-radius: 2px; overflow: hidden;margin-bottom: 1px;"> ${data.label}</span>
                </div>
              `);
            },
          };
        }
      });

      choices.passedElement.element.addEventListener('choise', (event) => {
        this.billing.country = event.detail.value.value
      })

      if (language_code) {
        this.billing.country = language_code
        choices.setChoiceByValue(language_code)
      }
      this.countryInitialized = true;
    },

    initProductField () {
      const that = this
      const elements = document.querySelectorAll('#tp_quote select#product');
      if (elements.length === 0) return;
      elements.forEach((element) => {
        const choices = new Choices(element, {
          allowHTML: true,
          searchEnabled: true,
          searchChoices: true,
          shouldSort: false,
          searchFields: ['label', 'value'],
          loadingText: this.colorLoadingText,
          noResultsText: this.colorNoResultsText,
          noChoicesText: this.colorNoChoicesText,
          itemSelectText: '',
          callbackOnCreateTemplates: function (template) {
            return {
              item: ({
                classNames
              }, data) => {
                let currentProduct = that.products.find((product) => product.id === parseInt(data.value))
                if (!currentProduct) {
                  currentProduct = {
                    title: data.value,
                    featured_image: {}
                  }
                }
                return template(`
                  <div class="${classNames.item} ${
                  data.highlighted
                    ? classNames.highlightedState
                    : classNames.itemSelectable
                } ${
                  data.placeholder ? classNames.placeholder : ''
                }" data-item data-id="${data.id}" data-value="${data.value}" ${
                  data.active ? 'aria-selected="true"' : ''
                } ${data.disabled ? 'aria-disabled="true"' : ''}>
                    <span class="flex flex-row gap-2 items-center text-sm">
                    ${ Object.prototype.hasOwnProperty.call(currentProduct.featured_image, 'url') ? '<img class="border border-darkblue-500 rounded-sm overflow-hidden !max-h-[36px] !w-auto" src="' + currentProduct.featured_image.url + '" width="' + currentProduct.featured_image.width + '" height="' + currentProduct.featured_image.height + '">' : '' }
                      <span>${currentProduct.title}</span>
                    </span>
                  </div>
                `);
              },
              choice: ({
                classNames
              }, data) => {
                let currentProduct = that.products.find((product) => product.id === parseInt(data.value))
                if (!currentProduct) {
                  currentProduct = {
                    title: data.value,
                    featured_image: {}
                  }
                }
                return template(`
                  <div class="${classNames.item} ${classNames.itemChoice} ${
                  data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                }" data-select-text="${this.config.itemSelectText}" data-choice ${
                  data.disabled
                    ? 'data-choice-disabled aria-disabled="true"'
                    : 'data-choice-selectable'
                } data-id="${data.id}" data-value="${data.value}" ${
                  data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                }>
                    <span class="flex flex-row gap-2 items-center text-sm">
                      ${ Object.prototype.hasOwnProperty.call(currentProduct.featured_image, 'url') ? '<img class="border border-darkblue-500 rounded-sm overflow-hidden !max-h-[36px] !w-auto" src="' + currentProduct.featured_image.url + '" width="' + currentProduct.featured_image.width + '" height="' + currentProduct.featured_image.height + '">' : '' }
                      <span>${currentProduct.title}</span>
                    </span>
                  </div>
                `);
              },
            };
          }
        });
      })
      this.productFieldInitialized = true
    },

    onProductSelect(product_id) {
      this.selectedProductId = parseInt(product_id);
      if (this.products && this.products.length > 0) {
        this.selectedProduct = this.products.find(product => product.id === parseInt(product_id));
      }
    },

    onFinish() {
      const errorWrapper = document.querySelector('#quote-error')
      const errorMessageWrapper = document.getElementById('quote-error-message')
      const submitButton = document.getElementById('quote-submit-button')
      jQuery.ajax({
        type: 'POST',
        url: toypek_quote.ajaxurl,
        dataType: 'json',
        data: {
          'nonce': toypek_quote.security,
          'action': 'toypek_quote_submit',
          'language': language_code,
          'product': JSON.stringify(this.selectedProductId),
          'quantity': this.quantity,
          'billing': JSON.stringify(this.billing)
        },
        beforeSend: function () {
          submitButton.disabled = true
          errorMessageWrapper.innerHTML = ''
          errorWrapper.classList.add('hidden')
          errorWrapper.classList.remove('flex')
        },
        success: function (response) {
          const { data } = response
          if (data.valid === true) {
            if (data.redirect_url && data.redirect_url !== '') {
              window.location.href = data.redirect_url
            } else {
              submitButton.disabled = false
            }
          } else {
            if (data.valid === false) {
              if (data.missing_fields && data.missing_fields.length > 0) {
                const form = document.getElementById('quote-form');
                
                data.missing_fields.forEach((f) => {
                  const inputs = form.querySelectorAll('input[name="' + f + '"]');
                  
                  inputs.forEach(function(input) {
                    input.classList.add('validation-error');
                    input.addEventListener('change', handleInputChange);
                    input.addEventListener('input', handleInputChange);
                  });
                })
                
                function handleInputChange(e) {
                  e.target.classList.remove('validation-error');
                }
              }
              if (data.message && data.message !== '') {
                errorMessageWrapper.innerHTML = data.message
                errorWrapper.classList.remove('hidden')
                errorWrapper.classList.add('flex')
                submitButton.disabled = false
              }
            }
          }
        },
        error: function (xhr, status, error) {
          console.log(error)
          console.log(xhr)
          console.log(status)
          submitButton.disabled = false
        }
      })
    },

    async fetchProducts() {
      const products = [];
      try {
        // Fetch the index.json files from the specified directory
        const response = await fetch(`/wp-content/themes/toypek/lib/products/${language_code}/index.json`);
        const productIds = await response.json();

        // Loop through the directories and fetch the index.json files
        for (const productId of productIds) {
          const indexResponse = await fetch(`/wp-content/themes/toypek/lib/products/${language_code}/${productId}/index.json`);
          const indexData = await indexResponse.json();
          products.push(indexData);
          this.products = products.filter(product => product.show_in_configurator === false);
        }       
        return products;
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loading = false;
      }
    },
  })
  )

}
Alpine.start()

// Find all the links on your site
const links = document.querySelectorAll('a');

// Iterate over each link
links.forEach(link => {
  // Check if the link's anchor contains "configurator"
  if (link.hash.includes('configurator')) {
    // Modify the link to include the desired attributes
    link.setAttribute('data-modal-trigger', '');
    link.setAttribute('aria-controls', 'modal_configurator');
    link.setAttribute('aria-expanded', 'false');
    link.setAttribute('type', 'button');

    // Prevent the default behavior of the link
    link.addEventListener('click', event => {
      event.preventDefault();
    });
  }
});
