import LazyLoad from "vanilla-lazyload";

const images = document.querySelectorAll("img.lazy, iframe");
const createLazyLoadInstance = (elements_selector = '.lazy') => {
	return new LazyLoad({
    unobserve_entered: true,
    webp: true,
    elements_selector: elements_selector
  }, 
  images
  );
};

const menuImages = document.querySelectorAll("img.menu-lazy");
const createMenuImages = () => {
	return new LazyLoad({
    unobserve_entered: true,
    webp: true,
    elements_selector: '.menu-lazy'
  }, 
  menuImages
  );
};


const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);

window.menuLazyload = () => {
  createMenuImages();
}